import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'bootstrap/dist/css/bootstrap.css'

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let token = localStorage.getItem('auth')
    if (!token) {
      next({
        path: '/login',
      })
    } else {
      next()
    }
  } else {
    let token = localStorage.getItem('auth')
    if (!token) {
      next()
    } else {
      next({
        path: '/',
      })
    }
  }
})

createApp(App).use(router).mount('#app')
